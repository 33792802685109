.breadcrumb {
  align-items: center !important;
  padding: $breadcrumb-padding-y 0;
  background: none;
  margin: 0;
  a,
  span {
    color: darkgrey;
    text-decoration: none;
    &.disabled {
      color: black;
      font-weight: bold;
      cursor: not-allowed;
    }
  }
  li {
    list-style: none;
    float: left;
    margin: 5px;
  }
}
