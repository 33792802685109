/*
+++++++++ Variable Overrides +++++++++
*/

// #730000 (https://rgb.to/730000)
$primary-h: 0;
$primary-s: 100;
$primary-b: 45;

// Input
$input-bg: #F5F5F5;
$input-font-weight: 700;

$main-bg-color: #E9ECEF;
$main-header-height: 110px;
$secondary-bg-color: #F5F5F5;

// Table
$table-th-font-size: 16px;
$table-th-font-weight: 600;
$table-cell-padding: 0.7em;

$btn-sm-min-width: 100px;

$white: #fff;

main {
  background-color: $main-bg-color;
}

.bg-color {
  background-color: $secondary-bg-color;
}

.color-bg {
  color: rgb(89, 103, 113);
}

.cursor-pointer {
  cursor: pointer;
}

$opacity-disable: 1;
