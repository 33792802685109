@import "~@ng-select/ng-select/themes/material.theme.css";

.ng-select {
  display: block;
  width: 100%;
  min-width: 100px;
  height: auto;
  min-height: $input-height;
  padding: 0.375rem 0 0 0;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  .ng-select-container {
    min-height: 38px;
    &:after {
      border-color: $gray-secondary;
    }
    .ng-value-container {
      padding: 0 $input-spacing-x;
      border-top: none;
      .ng-placeholder {
        color: $input-label-color !important;
        font-weight: 600;
        line-height: calc(#{$input-height} - 1px);
        font-size: 1rem;
        position: absolute;
        line-height: calc(#{$input-height} - 1px);
        transition: 0.2s ease-out;
        top: 0;
        display: block;
        max-width: 100%;
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 $input-spacing-x;
        z-index: 6;
      }
      .ng-value {
        color: $dark;
        font-size: $select-button-text-size;
        font-weight: bold;
      }
    }
    .ng-arrow-wrapper {
      .ng-arrow {
        color: $input-label-color;
      }
    }
  }

  .ng-dropdown-panel {
    .ng-dropdown-header {
      border-bottom: none;
      padding: 0;
      line-height: initial;
      min-height: 0;
    }
    .ng-dropdown-panel-items {
      //for IES
      padding-top: $v-gap;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      .ng-option {
        line-height: initial;
        padding: 0;
        min-height: 0;
        &-selected {
          background: transparent;
          a,
          span {
            &:not(.btn) {
              color: $neutral-1-a10 !important;
              text-decoration: underline;
            }
          }
        }
        &:hover {
          color: $primary;
        }
        &.ng-option-marked {
          background-color: transparent;
        }
        .ng-option-label {
          color: $input-label-color;
        }
        &.ng-option-selected {
          .ng-option-label {
            color: $primary;
          }
        }
      }
    }
    .ng-dropdown-footer {
      border-top: none;
      padding: 0;
      line-height: initial;
      min-height: 0;
    }

    &.ng-select-bottom {
      top: calc(100% - 3.25em);
      box-shadow: $select-dd-shadow;
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          background-color: transparent;
          margin: 0;
          .chip {
            margin: 0;
          }
        }
      }
    }
  }
  &.ng-select-focused {
    .ng-select-container {
      &:after {
        border-color: $gray-secondary;
        border-width: 1px;
      }
      .ng-value-container {
        .ng-placeholder {
          color: $input-label-color;
        }
      }
      .ng-arrow-wrapper {
        .ng-arrow {
          color: $input-label-color;
        }
      }
    }
  }
  &.ng-select-opened {
    z-index: 1050;
  }

  &.custom {
    .ng-dropdown-panel {
      width: 85vw;
      .ng-dropdown-panel-items {
        max-height: none;
        padding: 0;
        .ng-option {
          background: $white;
          padding: 0;
          cursor: default;
          &-selected {
            background: $white;
            a,
            span {
              &:not(.btn) &:not(.material-icons) {
                color: $neutral-1-a10 !important;
                text-decoration: none;
                font-weight: 600;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            .custom-search {
              .material-icons {
                text-decoration: none;
              }
            }
          }
        }
      }
      &.ng-select-bottom {
        top: calc(100% - 3.25em);
        box-shadow: $select-dd-shadow;
      }
    }
  }
}
.ng-dropdown-panel {
  &.ng-select-bottom {
    top: calc(100% - 3.25em);
    box-shadow: $select-dd-shadow;
  }
  .ng-dropdown-header {
    border-bottom: none;
    padding: 0;
    line-height: initial;
    min-height: 0;
  }
  .ng-dropdown-panel-items {
    //for IE
    padding-top: $v-gap;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    .ng-option {
      line-height: initial;
      padding: 0;
      min-height: 0;
      &:hover {
        color: $primary;
      }
      &.ng-option-marked {
        background-color: transparent;
      }
      .ng-option-label {
        color: $input-label-color;
      }
      &.ng-option-selected {
        .ng-option-label {
          color: $primary;
        }
      }
    }
  }
  .ng-dropdown-footer {
    border-top: none;
    padding: 0;
    line-height: initial;
    min-height: 0;
  }
}

.ng-input > input {
  padding: 0px;
  box-sizing: border-box;
}

.form-group {
  &.error {
    label {
      color: $danger;
    }
    .ng-select {
      .ng-select-container {
        &:after {
          border-color: $danger;
        }
      }
      .ng-arrow-wrapper {
        .ng-arrow {
          color: $danger;
        }
      }
    }
  }
  &.warning {
    label {
      color: $warning;
    }
    .ng-select {
      .ng-select-container {
        &:after {
          border-color: $warning;
        }
      }
      .ng-arrow-wrapper {
        .ng-arrow {
          color: $warning;
        }
      }
    }
  }
  &.success {
    label {
      color: $success;
    }
    .ng-select {
      .ng-select-container {
        &:after {
          border-color: $success;
        }
      }
      .ng-arrow-wrapper {
        .ng-arrow {
          color: $success;
        }
      }
    }
  }
}
