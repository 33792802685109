.form-group {
  .input-group {
    label {
      z-index: 3;
    }
    .input-group-append {
      background-color: $input-bg;
    }
  }
}
