$sidebar-width: 320px;

#sidebar {
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  transition: all 0.3s;
  margin-left: -$sidebar-width;
}

#sidebar.active {
  margin-left: 0;
}

@media (max-width: 768px) {
  #sidebar {
    margin-left: 0;
  }
  #sidebar.active {
    margin-left: -$sidebar-width;
  }
}
