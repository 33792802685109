table {
  margin-bottom: 0 !important;
  &.table-hover {
    tbody {
      tr {
        &:hover {
          cursor: pointer;
        }
        td {
          .form-check {
            margin-top: 0;
            [type="radio"] + label {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
  th {
    font-size: $table-th-font-size;
    font-weight: $table-th-font-weight;
  }
  thead {
    background-color: $primary;
    color: $white;
    tr {
      th {
        &.sortable {
          cursor: pointer;
        }
      }
    }
  }
  thead,
  tbody {
    .table-check {
      label {
        position: static !important;
        padding-left: 36px !important;
        cursor: pointer !important;
        display: inline-block !important;
        height: auto !important;
        line-height: 32px !important;
        font-size: 1rem !important;
        -webkit-user-select: none !important;
        -moz-user-select: none !important;
        -ms-user-select: none !important;
        user-select: none !important;
      }
      margin-top: 0px;
    }
  }
}

.table-sm {
  th {
    padding: $table-cell-padding-sm $table-cell-padding-sm * 2;
  }
}

.table-footer {
  background-color: $secondary-bg-color;
  padding: $table-cell-padding;
  height: 65px;

  .table-footer__action {
    margin-bottom: -60px;
    background-color: $white;
  }

  .table-footer__text {
    position: relative;
    right: 0;
    text-align: end;
    font-weight: $font-weight-bold;

    label {
      margin-bottom: 0;
    }

    ~ .table-footer__action {
      margin-bottom: 0;
    }
  }

  &.no-table {
    background-color: transparent;
    height: auto;
    .table-footer__action {
      margin-bottom: 0;
    }
  }
}
