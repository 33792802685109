.it-list-wrapper {
  .it-list {
    a {
      // hover
      &:hover {
        cursor: default;
        box-shadow: none;
        // color: $primary-a5;
        text-decoration: none;
        background: none;
        position: relative;
        // z-index: 1;
        transition: none;
        .it-right-zone {
          border-bottom: 1px solid $list-border-color;
        }
        span.text {
          text-decoration: underline;
        }
      }
    }
  }
}
