.virtual-scroll {
  height: 200px;
  max-height: 250px;
  overflow-y: auto;
  
  @include media-breakpoint-down(md) {
    max-height: 200px;
  }

  &.custom {
    height: 410px;
    max-height: 410px;
    overflow-y: hidden;
    overflow-x: auto;
    .item {
      width: 220px;
      .form-check {
        line-height: 0;
      }
    }
  }
}