.collapse-wrapper {
  &.shadow-border-left {
    border-left-style: solid;
    border-left-width: 6px;
    border-radius: 5px;
    border-left-color: $white;
    &:focus {
      border-left-color: $primary;
    }
    &:focus-within {
      border-left-color: $primary;
    }
    &.success {
      border-left-color: $success;
      &:focus {
        border-left-color: $success;
      }
      &:focus-within {
        border-left-color: $success;
      }
    }
    &.error {
      border-left-color: $danger;
      &:focus {
        border-left-color: $danger;
      }
      &:focus-within {
        border-left-color: $danger;
      }
    }
  }
}

.collapse-div {
  border-bottom: none;
  .collapse-header {
    *[data-toggle="collapse"] {
      border-top: none;
      &:focus {
        outline: none;
      }
      &:hover {
        text-decoration: none;
      }
      &:before {
        content: url("../../assets/svg/freccia.svg");
      }
      &[aria-expanded="false"] {
        color: $gray-secondary;
      }
    }
  }
  &.collapse-radio-group {
    border: none;
    .collapse-header {
      button {
        &:before {
          height: 0;
          width: 0;
        }
      }
    }
  }
}
