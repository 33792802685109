.bootstrap-select-wrapper {
  .dropdown {
    &.bootstrap-select {
      .dropdown-menu {
        box-shadow: none;
        padding-top: $v-gap;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        //for IE
        top: calc(100% - 2.5em) !important;
        li {
          a {
            padding: ($v-gap) $v-gap * 2;
          }
        }
        .dropdown-item {
          &:hover {
            background-color: transparent;
            text-decoration: underline !important;
          }
        }
      }
      .bs-footer {
        padding: ($v-gap) $v-gap * 3;
        > * {
          height: $input-height;
          display: -ms-flexbox !important;
          display: flex !important;
          -ms-flex-align: center !important;
          align-items: center !important;
          padding: 0;
          border-top: 1px solid $select-dd-small-separator-bg;
          font-size: $select-dd-text-size;
          font-weight: $font-weight-normal;
        }
      }
    }
  }
}
