vvf-header {
  .it-header-wrapper {
    background-position-y: center;
    background-size: cover;
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/assets/img/header.jpg');
    background-size:cover;
  }

  .it-header-slim-wrapper {
    background-color: transparent;
  }

  .it-header-center-wrapper {
    background-color: transparent;
  }
}
