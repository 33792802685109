shared-notify-bell {
  cursor: pointer;
  .notificheBell {
    border-radius: 50%;
    color: white;
    background-color: #d40101;
    /* z-index: 999; */
    font-size: 0.6rem;
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    text-align: center;
    padding: 1px 1px 2px 2px;
    font-weight: 400;
    top: -20px;
    left: -19px;
  }
}
