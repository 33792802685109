.form-group {
  label {
    z-index: 9;
  }
  &.form-group--readonly {
    opacity: $opacity-disable;
  }

  &-link {
    label {
      left: 4rem !important;
    }
  }
}

.form-control {
  box-shadow: none;
  &.no-bg {
    background-color: transparent;
  }
  &[readonly] {
    background-color: transparent;
    opacity: $opacity-disable;
    cursor: default;
    border-bottom: none;

    + label {
      cursor: default;
    }

    &.no-readonly {
      background-color: transparent;
      // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
      opacity: 1;
    }

    &.readonly-border-bottom {
      border-bottom: 1px solid #5c6f82;
    }

    &[ngbdatepicker] {
      opacity: 1;
    }
  }

  &:disabled {
    &[ngbdatepicker] {
      background-color: $input-disabled-bg;
      // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
      opacity: 1;
    }
  }

  &:focus {
    color: $dark;
  }
}

.form-check {
  [type="radio"] {
    &.checked + label {
      &::after {
        border-color: $primary;
        background-color: $primary;
        z-index: 0;
        transform: scale(0.64);
      }

      &::before {
        border-color: $primary;
      }
    }
  }

  &.read-only {
    [type="checkbox"] + label,
    [type="radio"] + label {
      cursor: default;
    }
  }

  &.form-check-group {
    &.no-box-shadow {
      box-shadow: none;
    }
  }
}

textarea {
  &[readonly] {
    border: none;
    resize: none;
  }
}

.valid-feedback,
.invalid-feedback,
.warning-feedback {
  display: block !important;
}

.input-group {
  .input-group-prepend,
  .input-group-append {
    .btn {
      border-radius: 0;
    }
  }
}

.custom-control {
  margin-top: 0.5rem;
  height: 32px;
  line-height: 32px;
  margin-bottom: 0.5rem;
  &.custom-checkbox {
    .custom-control-input {
      &:not(:checked) ~ .custom-control-label {
        &::before {
          background-color: transparent;
          border-color: #5c6f82;
          border-style: solid;
          border-width: 2px;
        }
      }
      &:checked ~ .custom-control-label {
        &::before {
          border-color: $primary;
          background-color: $primary;
        }
      }
    }
    .custom-control-label {
      cursor: pointer;
      padding-left: 30px;
      &::before {
        left: 0;
        height: 20px;
        width: 20px;
      }
      &::after {
        left: 0;
        width: 1.1rem;
        height: 1.1rem;
      }
    }
  }
}
