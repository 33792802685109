.steppers {
  .steppers-header {
    a {
      text-decoration: none;
    }
  }
}

.steppers {
  .steppers-header {
    flex-wrap: wrap;

    li {
      font-weight: normal;
      text-transform: none;
      font-size: 0.8rem;
      padding: 0;
      display: flex !important;

      &:not(.active) {
        &:hover {
          .steppers-number,
          .label {
            cursor: pointer;
          }
        }
      }

      .steppers-number {
        width: 2rem;
        height: 2rem;
        vertical-align: middle;
        margin-right: 0;
        border: none;
        background-color: lighten($secondary, 20);
        color: $white;

        .number {
          position: relative;
          top: 7px;
        }

        .icon {
          position: relative;
          top: 5px;
        }
      }

      .label {
        margin-left: 5px;
      }

      &.error {
        .steppers-number {
          background: $warning;
          border-color: $warning;
          color: $white;
        }

        &.active {
          .steppers-number {
            background: $warning;
            border-color: $warning;
          }
          .label {
            color: $warning;
          }
        }
      }

      &.warning {
        // states
        &.active,
        &.confirmed {
          color: $warning;

          .icon {
            fill: $warning;
          }
        }

        // steppers
        &.steppers-index {
          span {
            &.active {
              color: $warning;
            }
          }
        }
      }

      &.success {
        // states
        &.active,
        &.confirmed {
          color: $success;

          .icon {
            fill: $success;
          }
        }

        // steppers
        &.steppers-index {
          span {
            &.active {
              color: $success;
            }
          }
        }
      }

      &:not(:first-child) {
        &::before {
          content: "";
          height: 1px;
          background-color: $secondary;
          width: 50px;
          margin-right: 15px;
        }
      }
    }
  }
}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .steppers {
    // header
    .steppers-header {
      // steps
      li {
        border-left: none;

        &:nth-last-child(2) {
          border-right: none;
        }

        &.error {
          // active
          &.active {
            &:after {
              background: $danger;
            }

            .steppers-number {
              background: $danger;
              border-color: $danger;
            }
          }

          .nav-link {
            color: $danger;
          }
        }

        &.warning {
          // active
          &.active {
            &:after {
              background: $warning;
            }

            .steppers-number {
              background: $warning;
              border-color: $warning;
            }
          }

          .nav-link {
            color: $warning;
          }
        }

        &.success {
          // active
          &.active {
            &:after {
              background: $success;
            }

            .steppers-number {
              background: $success;
              border-color: $success;
            }
          }

          .nav-link {
            color: $success;
          }
        }
      }
    }
  }
}
