//mobile
.chip {
    min-height: $v-gap * 3;
    height: auto;

    .chip-label {
        height: auto;
        min-height: $v-gap * 2;
        transform: none;
    }

    &.chip-lg {
        height: auto;

        .chip-label {
            height: auto;
            min-height: 12px;
            transform: none;
        }
    }
}