.link-list-wrapper {
  &--sidebar {
    .link-list {
      .list-item {
        display: flex;
        align-items: center;
        text-decoration: none !important;
        height: 45px;
        i {
          font-size: 1.5em;
          color: $secondary;
          width: 30px;
          text-align: center;
        }
        span {
          color: $secondary;
          margin-left: $link-list-h-pad / 2;
          font-weight: 600;
          font-size: 1rem;
          text-decoration: none;
          line-height: 1.3rem;
          margin-right: 0;
        }
        &.active {
          i {
            color: $primary;
          }
          span {
            color: $primary;
          }
        }
      }
    }
  }
}
