.chip {
  &.chip-lg {
    margin: 4px 8px 4px !important;
    i {
      position: relative;
      top: -7px;
      font-size: 20px;
      padding-left: 6px;
    }
  }
  &.chip-xl {
    border: none;
    height: $v-gap * 6;
    border-radius: $v-gap * 3;
    background-color: $secondary;
    .chip-label {
      color: $white;
    }
    &:hover:not(.chip-disabled) {
      background-color: $secondary;
    }
  }
}
