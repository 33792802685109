.autocomplete {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  background-color: $white;
  padding: $v-gap 0;
  box-shadow: $dropdown-box-shadow;
  border-top: 1px solid $gray-secondary;
  transition: opacity 0.3s;
  display: block;
  z-index: 10;
  max-height: 252px;
  overflow-y: auto;

  li {
    list-style-type: none;
    padding: 0;

    &.autocomplete-item {
      //single item
      a {
        padding: 12px $v-gap * 3;
        display: block;
        display: -ms-flexbox;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        color: $gray-primary;
        line-height: 1.2;
        font-size: 1rem;
        //hover state
        &:hover {
          color: $primary;
          text-decoration: none;

          .icon {
            fill: $primary;
          }

          .autocomplete-list-text {
            //item text
            span {
              text-decoration: underline;
            }

            //item label
            em {
              color: $primary;
            }
          }

          //search highlight
          mark {
            color: $primary;
          }
        }

        //item icon + avatar
        .icon,
        .avatar {
          margin-right: $v-gap * 2;
          flex-shrink: 0;
        }

        .icon {
          fill: $gray-primary;
        }

        //item text
        .autocomplete-list-text {
          margin-top: 2px;
          //item label
          em {
            font-style: normal;
            text-transform: uppercase;
            font-size: 0.75rem;
            color: $neutral-1-a8;
            font-weight: 600;
            letter-spacing: 0.1em;
            display: block;
            margin-top: $v-gap/2;
          }
        }
      }

      //search highlight
      mark {
        padding: 0;
        background: transparent;
        font-weight: bold;
      }
    }
  }
}
