@font-face {
    font-family: 'vvf-regular';
    src:     url('vvf-icons-vvf.ttf') format('woff'),
        url('vvf-icons-vvf.woff2') format('woff2'),
         url('vvf-icons-vvf.woff') format('woff');
    font-weight: normal!important;
    font-style: normal!important;

}

.vvf {
  line-height: 1em;
}

.vvf:before {
    font-family: 'vvf-regular'!important;
    font-weight: normal!important;
    font-style: normal!important;
    font-size:48px;
    text-decoration: none;
}

.xs.vvf:before {
    font-size:24px;
}

.sm.vvf:before {
    font-size:48px;
}

.md.vvf:before {
    font-size:60px;
}

.lg.vvf:before {
    font-size:80px;
}


.vvf.vvf-activity:before {
    content: 'A';
}

.vvf.vvf-analytics:before {
    content: 'B';
}

.vvf.vvf-docs:before {
    content: 'C';
}

.vvf.vvf-eat:before {
    content: 'D';
}
.vvf.vvf-emergency:before {
    content: 'E';
}
.vvf.vvf-esercitazione:before {
    content: 'H';
}
.vvf.vvf-esercitazione-2:before {
    content: 'G';
}
.vvf.vvf-esercitazione-3:before {
    content: 'F';
}
.vvf.vvf-evaluation-form:before {
    content: 'I';
}

.vvf.vvf-evaluation-form-2:before {
    content: 'J';
}
.vvf.vvf-event:before {
    content: 'a';
}
.vvf.vvf-fireman:before {
    content: 'b';
}
.vvf.vvf-fireman-2:before {
    content: 'c';
}
.vvf.vvf-firm:before {
    content: 'd';
}
.vvf.vvf-flame:before {
    content: 'e';
}
.vvf.vvf-logistic:before {
    content: 'f';
}
.vvf.vvf-material-5:before {
    content: 'g';
}
.vvf.vvf-material:before {
    content: 'k';
}
.vvf.vvf-material-2:before {
    content: 'i';
}
.vvf.vvf-material-3:before {
    content: 'j';
}
.vvf.vvf-material-4:before {
    content: 'h';
}
.vvf.vvf-multimedia:before {
    content: 'l';
}
.vvf.vvf-note:before {
    content: 'm';
}
.vvf.vvf-notification:before {
    content: 'o';
}
.vvf.vvf-notification-2:before {
    content: 'n';
}
.vvf.vvf-people-3:before {
    content: 'p';
}
.vvf.vvf-people-4:before {
    content: 'q';
}

.vvf.vvf-people-2:before {
    content: 'r';
}

.vvf.vvf-people:before {
    content: 's';
}


.vvf.vvf-place:before {
    content: 't';
}
.vvf.vvf-scenario:before {
    content: 'u';
}
.vvf.vvf-sleep:before {
    content: 'v';
}
.vvf.vvf-team-creation:before {
    content: 'w';
}
.vvf.vvf-team:before {
    content: 'x';
}
.vvf.vvf-trainer:before {
    content: 'y';
}

.vvf.vvf-training:before {
    content: 'z';
}


