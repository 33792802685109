.form-group {
  label {
    &.inherit {
      position: inherit;
      margin-bottom: 0;
    }
  }
  .form-check {
    label {
      font-weight: normal;
    }
  }
}
