.btn {
  &.btn-icon {
    .icon {
      &.material-icons {
        position: relative;
        top: 3px;
        margin-left: 7px;
      }
    }

    &[data-toggle="collapse"] {
      .icon {
        transition: transform 0.3s;
        -webkit-transition: -webkit-transform 0.3s;
      }

      &[aria-expanded="false"] {
        .icon {
          transform: scaleY(-1);
        }
      }
    }

    &.only-icon {
      padding: 12px 12px;

      &:focus,
      &:active {
        box-shadow: none !important;
      }
    }

    &:disabled {
      opacity: 1;

      * {
        color: $gray-disabled !important;
      }

      svg {
        fill: $gray-label-disabled !important;
      }
    }
  }

  &.btn-circle {
    text-align: center;
    box-shadow: none;
    color: $link-color;

    &:active {
      color: $link-color !important;
    }

    &.btn-sm {
      width: 30px;
      height: 30px;
      padding: 6px 0px;
      border-radius: 15px;
      font-size: 8px;
      text-align: center;
    }

    &.btn-md {
      width: 50px;
      height: 50px;
      padding: 7px 10px;
      border-radius: 25px;
      font-size: 10px;
    }

    &.btn-xl {
      width: 70px;
      height: 70px;
      padding: 10px 16px;
      border-radius: 35px;
      font-size: 12px;
      text-align: center;
    }
  }

  &.btn-rounded {
    border-radius: 30px;
  }

  &.no-focus {
    box-shadow: none !important;
  }
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: white !important;

  &.active {
    background-color: white !important;
  }

  &.show {
    background-color: white !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.btn-sm {
  min-width: $btn-sm-min-width;
}
