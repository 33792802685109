.modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        background-color: $secondary-bg-color;
        padding-bottom: $modal-padding;

        .title {
          margin-bottom: 0;
          color: $primary !important;
          text-transform: none;
          font-size: 1rem;
        }
      }

      .modal-footer {
        justify-content: flex-start;
      }
    }

    &.modal-xl {
      .modal-content {
        .modal-header {
          background-color: $white;

          .title {
            color: $primary;
            letter-spacing: normal;
            font-size: 1.555rem;
            line-height: 1.428;
            font-weight: 600;
          }
        }

        .modal-body {
          padding: 0 $modal-padding $modal-padding $modal-padding;
        }
      }
    }
  }
}

.modal-backdrop {
  z-index: 1040 !important;
}
