$header-slim-height: 60px;

@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .it-header-slim-wrapper {
    height: $header-slim-height;
    .it-header-slim-wrapper-content {
      height: $header-slim-height;
      .it-header-slim-right-zone,
      .header-slim-right-zone {
        height: $header-slim-height;
      }
    }
  }
}

.it-header-wrapper {
  .it-header-slim-wrapper {
    .it-header-slim-wrapper-content {
      img {
        &.navbar-brand {
          width: 120px;
        }
      }
    }
  }
}
