.grid-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1051;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  text-decoration: none;
}
