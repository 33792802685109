.account-container {
  width: 100%;
  height: 100vh;
  background-position-x: center;
  background-image: url('/assets/img/login-BG-desktop.jpeg');

  @include media-breakpoint-down(md) {
    background-image: url('/assets/img/login-BG-tablet.jpeg');
  }

  .login-container {
    width: 500px;
    margin: 0 auto;
    text-align: center;
    color: $white;

    @include media-breakpoint-down(md) {
      width: 100%;
      padding: 0 100px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 10px;
    }

    .logo {
      padding-bottom: 15px;
      img {
        height:170px;
      }
    }

    .input-group {
      margin: 50px 0;

      label {
        color: $white;
      }

      .input-group-prepend {
        .input-group-text {
          background-color: transparent;
          color: $white;
          border-bottom: 2px solid $white;
        }
      }

      input {
        &.form-control {
          background-color: transparent;
          color: $white;
          border-bottom: 2px solid $white;
        }

        &::placeholder {
          color: darken($white, 40);
        }

        &:-internal-autofill-selected {
          background-color: transparent !important;
          color: none !important;
        }
      }

      .input-group-append {
        button {
          border-bottom: 2px solid $white;
          border-radius: 0;

          i {
            color: $white;
          }
        }
      }
    }

    mat-form-field {
      &.mat-form-field {
        font-size: 18px;

        .mat-form-field-infix {
          margin-left: 15px;
          font-weight: 100;
        }

        .mat-form-field-label {
          color: darken($white, 30);
        }

        input {
          line-height: 1.4;
        }

        .mat-form-field-underline {
          background-color: $white !important;
        }

        &.mat-focused {
          .mat-form-field-ripple {
            background-color: $white;
          }

          .mat-form-field-label {
            color: darken($white, 30);
          }
        }
      }
    }

    button[type="submit"] {
      width: 100%;
      padding: 10px 0;
      font-size: 18px;
      font-weight: 300;

      &:disabled {
        cursor: not-allowed;
        background-color: darken($primary, 20);
      }
    }
  }
}
