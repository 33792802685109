$list-group-header-border-width: 3px;

.list-group {
  border-top: $list-group-header-border-width solid $list-group-border-color;
  &.no-border-top {
    border-top: none;
  }
  .list-group-item {
    &.no-border-first-child {
      &:first-child {
        border: none;
      }
    }
  }
}
