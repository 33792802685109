.pagination {
  .page-item {
    .page-link {
      text-decoration: none;
    }
    &.active {
      background-color: $primary;
      border-radius: $pager-item-border-radius;
      .page-link {
        color: $white !important;
      }
    }
    .page-link {
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}