a {
  color: $link-color;
}

a {
  &:not([href]) &:not(.btn) {
    color: $link-color !important;
    text-decoration: none !important;
    cursor: pointer;
  }
}
