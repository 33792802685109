$card-top-icon-size: 60px;

.card-wrapper {
  .card {
    .card-body {
      .card-title {
        &--main {
          background-color: $primary;
          color: $white;
          height: $main-header-height - 20px;
          padding-left: 20px;
          padding-right: 20px;
          display: flex;
          align-items: center;
          margin-bottom: 0;
          background: -moz-linear-gradient(
              180deg,
              rgba(115, 2, 0, 0.5) 0%,
              rgba(115, 2, 0, 0.5) 24%,
              rgba(115, 2, 0, 0.5) 45%,
              rgba(115, 2, 0, 0.5) 100%
          );
          background: -webkit-linear-gradient(
              180deg,
              rgba(115, 2, 0, 0.5) 0%,
              rgba(115, 2, 0, 0.5) 24%,
              rgba(115, 2, 0, 0.5) 45%,
              rgba(115, 2, 0, 0.5) 100%
          );
          background: linear-gradient(
              180deg,
              rgba(115, 2, 0, 0.5) 0%,
              rgba(115, 2, 0, 0.5) 24%,
              rgba(115, 2, 0, 0.5) 45%,
              rgba(115, 2, 0, 0.5) 100%
          );

          .chip-status {
            background: $secondary;
            height: 43px;
            min-width: 140px;
            border: none;
            border-radius: 30px;
            background: -moz-linear-gradient(
                180deg,
                rgba(92, 111, 130, 0.5) 0%,
                rgba(92, 111, 130, 0.5) 24%,
                rgba(92, 111, 130, 0.5) 45%,
                rgba(92, 111, 130, 0.5) 100%
            );
            background: -webkit-linear-gradient(
                180deg,
                rgba(92, 111, 130, 0.5) 0%,
                rgba(92, 111, 130, 0.5) 24%,
                rgba(92, 111, 130, 0.5) 45%,
                rgba(92, 111, 130, 0.5) 100%
            );
            background: linear-gradient(
                180deg,
                rgba(92, 111, 130, 0.5) 0%,
                rgba(92, 111, 130, 0.5) 24%,
                rgba(92, 111, 130, 0.5) 45%,
                rgba(92, 111, 130, 0.5) 100%
            );

            .chip-label {
              color: $white;
              font-size: 1rem;
            }
          }

          .btn-action {
            position: absolute;
            right: 40px;
            top: 60px;
          }
        }
      }

      &--main {
        // padding-top: $card-spacer-y * 2;
        padding-bottom: $card-spacer-y;
        background: $card-shadow-bg;
      }
    }

    &.card-big {
      .top-icon {
        position: relative;
        width: $flag-icon-w;
        height: $flag-icon-h;
        margin-left: $card-padding * 2;

        .icon {
          width: $card-top-icon-size;
          height: $card-top-icon-size;
          fill: $primary-a6;
        }
      }
    }

    &.card-bg {
      &--primary {
        background-color: $primary;
        height: 180px !important;
        @include border-radius($btn-border-radius, 0);

        a,
        span {
          color: $white;
        }

        .card-body {
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;

          .card-title {
            color: $white;
            margin-bottom: 0;
          }

          &--center {
            .card-title {
              text-align: center;
            }
          }

          &:hover {
            text-decoration: none;
          }
        }

        &:hover {
          background-color: lighten($color: $primary, $amount: 5);
          cursor: pointer;
        }

        &::after {
          content: none;
        }
      }

      &:after {
        margin-top: 0;
      }
    }
  }
}

.card {
  &.card-teaser {
    &.custom {
      padding: 8px 24px;

      .card-title {
        .icon {
          width: 70px;
          height: 70px;
        }
      }

      &.clickable {
        &:hover {
          background-color: lighten($color: $primary, $amount: 10) !important;
          cursor: pointer;
        }
      }
    }
  }
}

.readMoreText {
  right: 30px;
}
