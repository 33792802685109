.notification-container {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1051;

  .notification {
    position: relative;
    right: 0;
    bottom: 0;
    display: block;
    margin-top: 20px;
  }
}
