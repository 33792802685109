main {
  display: flex;
  width: 100%;
  align-items: stretch;
  min-height: 100vh;
  .content {
    width: 100%;
    padding: 20px;
    // min-height: 100vh;
    transition: all 0.3s;
    &--card {
      margin-top: -$main-header-height;

      .card {
        background-color: transparent;
      }
    }
  }
}
